.hexagon {
  position: relative;
  width: 100%;
  text-align: center;
  height: 65px;
  background: #ffffff;
}

.hexagon:after,
.hexagon:before {
  position: absolute;
  content: "";
  z-index: 1;
  top: 0;
  width: 0px;
  background: #ecf3ff;
  border-top: 33px transparent solid;
  border-bottom: 33px transparent solid;
}

.hexagon:before {
  left: 0;
  border-right: 30px #fff solid;
}
.hexagon:after {
  right: 0;
  border-left: 30px #fff solid;
}

@media screen and (min-width: 1536px) {
  .hexagon {
    width: 100%;
    height: 70px;
  }

  .hexagon:after,
  .hexagon:before {
    border-top: 35px transparent solid;
    border-bottom: 35px transparent solid;
  }
  .hexagon:before {
    border-right: 30px #fff solid;
  }
  .hexagon:after {
    border-left: 30px #fff solid;
  }
}
