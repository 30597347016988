.blue-bg {
  background: linear-gradient(180deg, #2152a9 12%, #ecf3ff 0%) !important;
}

@media screen and (min-width: 800px) {
  .footer-subscribe {
    font-size: 16px;
  }
  .blue-bg {
    background: linear-gradient(180deg, #2152a9 12.4%, #ecf3ff 0%) !important;
  }
}

@media (min-width: 1900px) {
  .footer-subscribe {
    font-size: 25px;
  }
  .blue-bg {
    background: linear-gradient(180deg, #2152a9 11.4%, #ecf3ff 0%) !important;
  }
}
