.blue-bg-markets {
  position: absolute;
  background-color: #1c4895;
  height: 100%;
  width: 100%;
  z-index: -1;
}

@media screen and (max-width: 1399px) {
  .blue-bg-markets {
    position: absolute;
    background-color: #1c4895;
  }
}

@media screen and (min-width: 1400px) {
  .blue-bg-markets {
    position: absolute;
    background-color: #1c4895;
    bottom: 0px;
  }
}
