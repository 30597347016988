.contact-us {
  margin-bottom: 25px;
}

.contact-us li {
  color: #000;
  list-style-type: disc;
  list-style-image: none;
  padding: 15px 0 !important;
}
.contact-us li:first-child {
  padding-top: 0px !important;
}
.contact-us li:last-child {
  padding-bottom: 0px !important;
}

.contact-us a {
  color: #2152a9 !important;
}

.contact-us .mail-link {
  color: #000 !important;
}
