.settings ul div {
  font-weight: bold;
}

.bold {
  font-weight: bold !important;
}

.settings li {
  display: none !important;
  font-weight: bold !important;
}
