@import url("https://fonts.googleapis.com/css2?family=Kreon:wght@300;700&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none !important;
  color: #000;
  cursor: pointer;
}

.body-link {
  text-decoration: underline !important;
  color: #609bfe !important;
  font-weight: 700;
}

li {
  list-style-image: url("./assets/list-disc.svg");
  /* font-size: ; */
  color: #609bfe;
}

/* li span {
  color: #000;
} */

.center-block {
  display: block !important;
  margin: auto !important;
}

.text-center {
  text-align: "center" !important;
}

.w-100 {
  width: 100% !important;
}
.dark-link {
  color: #000 !important;
}

.bg-dark-accent {
  background-color: #2152a9 !important;
  color: #fff !important;
}

.footer-upper {
  /* background-color: #2152a9 !important; */
  position: relative;
  background-image: url("./assets/footer-bg.svg");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  /* padding-top: 50px; */
  padding-top: 5px;
}
/* .footer-upper:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-left: 50vw solid #ecf3ff;
  border-right: 50vw solid #ecf3ff;
  border-bottom: 50px solid #2152a9;
  width: 0;
} */

.footer-bottom {
  background-color: #1c4895;
}

.modal-footer-upper {
  /* background-color: #2152a9 !important; */
  position: relative;
  background-image: url("./assets/footer-bg.svg");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding-top: 50px;
  width: 100%;
}
.modal-footer-upper:before {
  content: "";
  width: 0;
  position: absolute;
  top: 0;
  right: 0;
  /* border-top: 50px solid #2152a9; */
  /* padding-top: 50px; */
  border-left: 150px solid #ecf3ff;
  border-right: 150px solid #ecf3ff;
  border-bottom: 500px solid #2152a9;
}

.blue-bg {
  background: linear-gradient(180deg, #2152a9 19%, #ecf3ff 0%) !important;
}

.list-disc {
  list-style-type: disc !important;
  font-weight: 500 !important;
}
.modal-style {
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%; -50%)";
  width: 400px;
  /* background-color: "background.paper"; */
  border: "2px solid #000";
  box-sizing: 24px;
  padding: 4px;
}

.content-hr {
  display: inline-block;
}
.content-hr:after {
  content: "";
  display: block;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 2px;
}
.no-scroll-bar {
  max-height: 100%;
}
.no-scroll-bar::-webkit-scrollbar {
  display: none !important;
}

.MuiInputBase-input {
  background-color: #fff !important;
}

.table-no-padding .MuiTableCell-root {
  padding: 0;
}

.calender-icon .MuiButtonBase-root {
  color: #5f99ff !important;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.openable-img {
  max-width: 100%;
  cursor: pointer;
  display: block;
  margin: "auto";
}

.b-link {
  cursor: pointer;
  color: #5f99ff;
}

.close-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pointer {
  cursor: pointer !important;
}
.c-pointer {
  cursor: pointer !important;
  font-size: inherit !important;
}
.b-flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.b-relative {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 4px 0px;
}

.c-absolute {
  cursor: pointer !important;
  font-size: inherit !important;
  position: absolute;
  right: 1%;
  top: 10px;
}

.modal-footer {
  background-image: url("./assets/modal-footer.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

@media screen and (max-width: 1280px) {
  .w-100 {
    width: 80% !important;
  }
}

@media screen and (min-width: 800px) {
  .footer-subscribe {
    font-size: 16px;
  }
  .blue-bg {
    background: linear-gradient(180deg, #2152a9 19.4%, #ecf3ff 0%) !important;
  }
}

@media (min-width: 1900px) {
  .footer-subscribe {
    font-size: 25px;
  }
  .blue-bg {
    background: linear-gradient(180deg, #2152a9 18.4%, #ecf3ff 0%) !important;
  }
}
