.blue-bg-numismatic {
  position: absolute;
  background-color: #1c4895;
  z-index: -1;
}
.blue-bg-history {
  position: absolute;
  background-color: #1c4895;
  z-index: -1;
}

@media screen and (max-width: 1399px) {
  .blue-bg-numismatic {
    height: 50vh;
    width: 98.7vw;
    top: 535px;
    overflow: hidden !important;
  }
  .blue-bg-history {
    height: 50vh;
    width: 98.7vw;
    top: 535px;
    overflow: hidden !important;
  }
}

@media screen and (min-width: 1400px) {
  .blue-bg-numismatic {
    height: 50vh;
    width: 98.7vw;
    top: 700px;
    overflow: hidden !important;
  }
  .blue-bg-history {
    height: 50vh;
    width: 98.7vw;
    top: 670px;
    overflow: hidden !important;
  }
}
